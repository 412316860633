import gql from 'graphql-tag';

const response = `response {
    status
    code
    message
}`;

const data = ` data {
    id
    publicationUniqueId
    publicationTitle
    publicationDescription
    publicationExpireDate
    publicationStatus
    publicationType
    publicationIsActive
    publicationCreateddate
    publicationAuthor
    publicationAuthorPhoto
    publicationPageCover
    publicationYear
    publisher
    publicationCategory{
      id
      publicationCategoryUniqueId
      publicationCategoryName
      publicationCategoryDescription
      publicationCategoryIsActive
      publicationCategoryCreateddate
    }
   
    publicationAttachments {
      id
      publicationAttachmentUniqueId
      publicationAttachmentName
      publicationAttachmentPath
      publicationAttachmentFileType
      publicationAttachmentCreateddate
      publicationAttachmentIsActive
      publication
    }
  }`;
const data2 = `data {
    id
    publicationAttachmentUniqueId
    publicationAttachmentName
    publicationAttachmentPath
    publicationAttachmentFileType
    publicationAttachmentCreateddate
    publicationAttachmentIsActive
    publicationAttachmentIsDownloaded
    publication
  }
`;
const data3 = ` data {
    id
    publicationAttachmentUniqueId
    publicationAttachmentName
    publicationAttachmentPath
    publicationAttachmentFileType
    publicationAttachmentCreateddate
    publicationAttachmentIsActive
    publication
    publicationAttachmentIsDownloaded
  }`;
const ongoingReseachData = `data{
  id
    proposalUniqueId
    proposalTitle
      proposalDescription
      researchProposalDoc
      proposalAuthor {
        id
        profileUniqueId
        firstName
        lastName
        profilePicture
      }
    proposalCreatedDate
}`


const categoryData = `data {
    id
    publicationCategoryUniqueId
    publicationCategoryName
    publicationCategoryDescription
    publicationCategoryIsActive
    publicationCategoryCreateddate
   
  }`
const researchAreaData = `data {
  id
  researchAreaUniqueId
  researchAreaName
  researchAreaPhoto
  researchAreaDescription
  researchAreaIsActive
  researchAreaCreateddate
}`
const page = `page {
    number
    hasNextPage
    hasPreviousPage
    nextPageNumber
    previousPageNumber
  }`

export const getResearchPublicationsData = gql`query getResearchPublicationsData($filtering: ResearchPublicationFilteringObject!) {
    getResearchPublicationsData(filtering: $filtering) {
      ${response}
      ${data}
      ${page}
    }
}`;
export const getMyResearchPublicationsData = gql`query getMyResearchPublicationsData($filtering: ResearchPublicationFilteringObject!) {
    getMyResearchPublicationsData(filtering: $filtering) {
      ${response}
      ${data}
      ${page}
    }
}`;

export const getPublicationAttachmentsData = gql`query getPublicationAttachmentsData($filtering: ResearchPublicationAttachmentFilteringObject) {
    getPublicationAttachmentsData(filtering: $filtering) {
      ${response}
      ${data2}
    }
}`;
export const createResearchPublications = gql`mutation createResearchPublications($input: ResearchPublicationInputObject) {
    createResearchPublications(input: $input) {
      ${response}
      ${data}
    }
}`;

export const updateResearchPublications = gql`mutation updateResearchPublications($input: ResearchPublicationInputObject) {
    updateResearchPublications(input: $input) {
        ${response}
        ${data}
    }
}`;



export const deleteResearchPublications = gql`mutation deleteResearchPublications($publicationUniqueId: String!) {
    deleteResearchPublications(publicationUniqueId: $publicationUniqueId) {
      ${response}
      ${data}
    }
}`;

export const publishResearch = gql`mutation PublishResearch($publicationUniqueId: String!) {
    publishResearch(publicationUniqueId: $publicationUniqueId) {
      ${response}
      ${data}
    }
}`;

export const unPublishResearch = gql`mutation unPublishResearch($publicationUniqueId: String!) {
    unPublishResearch(publicationUniqueId: $publicationUniqueId) {
      ${response}
      ${data}
    }
}`;

export const createResearchPublicationAttachments = gql`mutation createResearchPublicationAttachments($input: ResearchPublicationAttachmentInputObject) {
    createResearchPublicationAttachments(input: $input) {
      ${response}
      ${data3}
    }
}`;


export const deleteResearchPublicationAttachments = gql`mutation deleteResearchPublicationAttachments($publicationAttachmentUniqueId: String!) {
    deleteResearchPublicationAttachments(publicationAttachmentUniqueId: $publicationAttachmentUniqueId) {
      ${response}
      ${data3}
    }
}`;

export const getPublicationCategoryData = gql`query getPublicationCategoryData($filtering: PublicationCategoryFilteringObject) {
  getPublicationCategoryData(filtering: $filtering) {
      ${response}
      ${categoryData}
    }
}`;
export const createPublicationsCategory = gql`mutation createPublicationsCategory($input: PublicationCategoryInputObject) {
    createPublicationsCategory(input: $input) {
      ${response}
      ${categoryData}
    }
}`;
export const updatePublicationCategory = gql`mutation updatePublicationsCategory($input: PublicationCategoryInputObject) {
  updatePublicationsCategory(input: $input) {
      ${response}
      ${categoryData}
    }
}`;
export const deletePublicationsCategory = gql`mutation deletePublicationsCategory($publicationCategoryUniqueId: String!) {
  deletePublicationsCategory(publicationCategoryUniqueId: $publicationCategoryUniqueId) {
      ${response}
      ${categoryData}
    }
}`;
export const getResearchAreasData = gql`query getResearchAreasData($filtering: ResearchPririotiesAreasFilteringObject) {
  getResearchAreasData(filtering: $filtering) {
      ${response}
      ${researchAreaData}
    }
}`;

export const addResearchArea = gql`mutation addResearchArea($input: ResearchPririotiesAreasInputObject) {
  addResearchArea(input: $input) {
      ${response}
      ${researchAreaData}
    }
}`;
export const updateResearchArea = gql`mutation updateResearchArea($input: ResearchPririotiesAreasInputObject) {
  updateResearchArea(input: $input) {
      ${response}
      ${researchAreaData}
    }
}`;
export const deleteResearchData = gql`mutation deleteResearchData($researchAreaUniqueId: String!) {
  deleteResearchData(researchAreaUniqueId: $researchAreaUniqueId) {
      ${response}
      ${researchAreaData}
    }
}`;



export const getOnGoingResearchData = gql`query getOnGoingResearchData($filtering: ResearchProposalFilteringbject) {
  getOnGoingResearchData(filtering: $filtering) {
      ${response}
      ${ongoingReseachData}
    }
}`;


