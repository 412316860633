import { createCustomUser, createNewUser, passwordChange, passwordReset, refreshToken as refreshTokenMutation, resendActivationEmail, revokeToken, 
    sendPasswordResetEmail, tokenAuth, verifyAccount, verifyToken, customUserActivation, customUserRegistration } from "@/graphql/uaa/authentication.graphql"
import { getUserProfile, updateUserProfile } from "@/graphql/uaa/user.graphql"
import i18n from "@/i18n"
import { confirmDialog, notifyErrorLarge, notifySuccessLarge, notifyError, notifySuccess, notifyVerifyEmail } from "@/plugins/notification.service"
import router from "@/router"
import { apolloClient } from "@/vue-apollo"

export default {
    state: {
        user: {},
        userProfile: {}
    },
    getters: {
        getTokenState: (state) => state.user?.token,
        getLoggedUser: (state) => state.user,
        getUserProfile: (state) => state.userProfile
    },
    mutations : {
        setTokenState: (state, item) => {
            state.user.token = item
        },
        setLoggedUser: (state, item) => {
            state.user = {...state.user,...item} 
        },
        setUserProfile: (state, item) => {
            state.userProfile = item
        },
    },
    actions: {
        async logoutHandler(context) {
            await confirmDialog(i18n.t("labels.logoutPrompt"))
                .then(async (agree) => {
                    context.commit("setLoadingState", true)
                    if (agree) {
                        await apolloClient.mutate({
                            fetchPolicy: "no-cache",
                            mutation: revokeToken,
                            variables: {
                                refreshToken: `${context.getters.getLoggedUser?.refreshToken}`,
                            },
                        })
                            .then((response) => {
                                let { success } = response.data.revokeToken;

                                context.commit("RESET_STATE", { root: true });
                                router.push("/");
                                return success;
                            })
                            .finally(() => {
                                context.commit("setLoadingState", false);
                            });
                    } else {
                        router.back()
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async sendPasswordResetEmail(context, email) {
            context.commit("setLoadingState", true);
            await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: sendPasswordResetEmail,
                    variables: {email},
                })
                .then((response) => {
                    let { success } = response.data.sendPasswordResetEmail;
                    if (success) {
                        notifySuccessLarge(`If an account with this email exists, a password reset link will be sent`)
                        router.push("/");
                    } else {
                        notifyErrorLarge(`Failed to send email`)
                    }
                })
                .finally(() => { context.commit("setLoadingState", false); });
        },
        async resendActivationEmail(context, email) {
            context.commit("setLoadingState", true);
            await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: resendActivationEmail,
                    variables: {email},
                })
                .then((response) => {
                    let { success } = response.data.resendActivationEmail;
                    if (success) {
                        notifySuccessLarge(`Email sent to ${email}. Please check your inbox`)
                        router.push("/");
                    } else {
                        notifyErrorLarge(`Failed to send email to ${email}.`)
                    }
                })
                .finally(() => { context.commit("setLoadingState", false); });
        },
        async getUserProfile(context) {
            context.commit("setLoadingState", true);
            return await apolloClient.query({
                    fetchPolicy: "no-cache",
                    query: getUserProfile,
                })
                .then((output) => {
                    let { response, data } = output.data.getUserProfile;
                    if (!response.status) {
                        notifyError(response.message || 'Failed to fetch!')
                    } else {
                        context.commit("setLoggedUser", data)
                    }
                    return data
                })
                .finally(() => { context.commit("setLoadingState", false); });
        },
        async loginUser(context, data) {
            context.commit("setLoadingState", true);
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: tokenAuth,
                variables: _.omit(data, ['rememberMe']),
            })
                .then(async (response) => {
                    let { token, user, errors, success, refreshToken } = response.data.tokenAuth;
                    if (success) {
                        context.commit("setTokenState", token);
                        context.commit("setLoggedUser", { ...user, refreshToken, 'rememberMe': data.rememberMe });
                        notifySuccessLarge(
                            (user?.lastLogin?.length) ? 
                            `Last Login: ${new Date(user?.lastLogin).toLocaleDateString("en-US")}` : 
                            `Welcome to ${process.env.VUE_APP_NAME || 'Ubunifu portal'}`, 
                            "User login successfully!")
                        
                    } else {
                        if(errors.nonFieldErrors[0]?.message.includes('verify')) {
                            notifyVerifyEmail(errors.nonFieldErrors[0]?.message, data?.email)
                        } else {
                            notifyErrorLarge(errors.nonFieldErrors[0]?.message)
                        }
                    }
                    return success;
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async passwordChange(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: passwordChange,
                variables: input
            })
            .then((output) => {
                let {success,errors,refreshToken,token} = output.data.passwordChange
                
                if (success) {
                    context.commit("setLoggedUser", {refreshToken,token})
                    notifySuccess("Password Changed Successfully")
                } else {
                    notifyError(Object.entries(errors).map(([, value], index) => (value[index].message)).join("\n").toString() || i18n.t('labels.operationFailed'))
                }
                return success;
            }).finally(() => context.commit("setLoadingState", false))
        },
        async verifyToken(context, token) {
            context.commit("setLoadingState", true)
            if (!token?.length) {
                token = context.getters.getTokenState
            }
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: verifyToken,
                variables: {token}
            })
            .then((output) => {
                let {success,errors} = output.data.verifyToken
                if (!success) {
                    notifyError(Object.entries(errors).map(([, value], index) => (value[index].message)).join("\n").toString() || i18n.t('labels.operationFailed'))
                    context.commit("RESET_STATE", {root: true});
                    router.push("/").catch(() => {});
                } 
                return success
            }).finally(() => context.commit("setLoadingState", false))
        },
        async verifyAccount(context, token) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: verifyAccount,
                variables: {token}
            })
            .then((output) => {
                let {success,errors} = output.data.verifyAccount
                
                if (!success) {
                    notifyError(errors.toString() || i18n.t('labels.operationFailed'))
                } 
                
                notifySuccess("Account activated successfuly");
                router.push({ name: "Login"})

                return success
            }).finally(() => context.commit("setLoadingState", false))
        },
        async passwordReset(context, token) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: passwordReset,
                variables: {...token}
            })
            .then((output) => {
                let {success,errors} = output.data.passwordReset
                
                if (!success) {
                    notifyError(Object.entries(errors).map(([, value], index) => (value[index].message)).join("\n").toString() || i18n.t('labels.operationFailed'))
                }
                else{
                    notifySuccess("Password Reset Successfully")
                }

                router.push({ name: "Login"})

            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateUserProfile(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateUserProfile,
                variables: {input}
            })
            .then((output) => {
                let {response,data} = output.data.updateUserProfile
                
                if (!response.status) {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                } else {
                    if (input?.profileUniqueId == context.getters['getLoggedUser']['profileUniqueId']) {
                        context.commit('setLoggedUser', data)
                    } else {
                        context.commit("setUserProfile", data)
                    }
                    notifySuccess(response.message)
                    context.dispatch("getUserProfile")
                    return data
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async refreshToken(context, refreshToken) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: refreshTokenMutation,
                variables: {refreshToken}
            })
            .then((output) => {
                let {success,errors, token} = output.data.refreshToken
                
                if (!success) {
                    notifyError(errors.toString() || i18n.t('labels.operationFailed'))
                } else {
                    context.commit("setTokenState", token)
                }
                return success
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createNewUser(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createNewUser,
                variables: input
            })
            .then((output) => {
                let {success,errors,refreshToken,token} = output.data.createNewUser
                
                if (!success) {
                    notifyError(Object.entries(errors).map(([, value], index) => (value[index].message)).join("\n").toString() || i18n.t('labels.operationFailed'))
                } else {
                    refreshToken,token
                }
                return success
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createCustomUser(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createCustomUser,
                variables: {input}
            })
            .then((output) => {
                let {response} = output.data.createCustomUser
                
                if (!response.status) {
                    notifyError(Object.entries(errors).map(([, value], index) => (value[index].message)).join("\n").toString() || i18n.t('labels.operationFailed'))
                } else {
                    notifySuccess(response.status || "User account created!")
                }
                return response.status
            }).finally(() => context.commit("setLoadingState", false))
        },
        async customUserActivation(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: customUserActivation,
                variables: {input}
            })
            .then((output) => {
                let {response} = output.data.customUserAccoutActivation
                
                if (!response.status) {
                    notifyError(Object.entries(errors).map(([, value], index) => (value[index].message)).join("\n").toString() || i18n.t('labels.operationFailed'))
                } else {
                    notifySuccess(response.message || "User account activated!")
                }
                return response.status
            }).finally(() => context.commit("setLoadingState", false))
        },
        async customUserRegistration(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: customUserRegistration,
                variables: {input}
            })
            .then((output) => {
                let {response} = output.data.customUserRegistration
                
                if (!response.status) {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                } else {
                    notifySuccess(response.message || "User account created!")
                }
                return response.status
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}