import gql from "graphql-tag";
import {responseField} from "@/graphql/utils/base.graphql";


export const innovationCategory = `
id
                    innovationCategoryUniqueId
                    innovationCategoryName
                    innovationCategoryDescription
                    innovationCategoryIsActive
                    innovationCategoryCreatedDate`


export const innovationFields = `
    id
    innovationUniqueId
    innovationName
    innovationDescription
    innovationImageUrl
    innovationSiteUrl
    innovationCategory {
    ${innovationCategory}
    }
    innovationIsActive
    innovationCreatedDate
    innovationCreatedBy {
    id
    profileUniqueId
    username
    firstName
    middleName
    lastName
    profilePicture
    gender
    institution
    role
    dateJoined
    }`

export const innovationCategoryFields = `
id
      innovationCategoryUniqueId
      innovationCategoryName
      innovationCategoryDescription
      innovationCategoryIsActive
      innovationCategoryCreatedDate`

export const getInnovationCategoryData = gql`
    query GetInnovationCategoryData($filtering: InnovationCategoryFilteringInputObject) {
        getInnovationCategoryData(filtering: $filtering) {
            ${responseField}
            data {
                ${innovationCategoryFields}
            }
        }
    }`

export const createInnovationCategoryMutation = gql`
    mutation CreateInnovationCategoryMutation($input: InnovationCategoryInputObject) {
        createInnovationCategoryMutation(input: $input) {
            ${responseField}
            data {
                ${innovationCategoryFields}
            }
        }
    }`

export const updateInnovationCategoryMutation = gql`
    mutation UpdateInnovationCategoryMutation($input: InnovationCategoryInputObject) {
        updateInnovationCategoryMutation(input: $input) {
            ${responseField}
            data {
                ${innovationCategoryFields}
            }
        }
    }`

export const deleteInnovationCategoryMutation = gql`
    mutation DeleteInnovationCategoryMutation($uniqueId: String!) {
        deleteInnovationCategoryMutation(uniqueId: $uniqueId) {
            ${responseField}
            data {
                ${innovationCategoryFields}
            }
        }
    }`
export const getInnovationsData = gql`
    query GetInnovationData($filtering: InnovationsFilteringObject) {
        getInnovationData(filtering: $filtering) {
           ${responseField}
            data {
                ${innovationFields}
            }
        }
    }`


export const createInnovation = gql`
    mutation AddInnovationMutation($input: InnovationsInputObject) {
        addInnovationMutation(input: $input) {
            ${responseField}
            data {
                ${innovationFields}
            }
        }
    }`

export const updateInnovationData = gql`
    mutation UpdateInnovationMutation($input: InnovationsInputObject) {
        updateInnovationMutation(input: $input) {
            ${responseField}
            data {
                ${innovationFields}
            }
        }
    }`

export const deleteInnovationMutation =  gql`
    mutation DeleteInnovationMutation($innovationUniqueId: String!) {
        deleteInnovationMutation(innovationUniqueId: $innovationUniqueId) {
            ${responseField}
            data {
                ${innovationFields}
            }
        }
    }`
