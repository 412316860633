import {apolloClient} from "@/vue-apollo";
import {
    createInnovation, deleteInnovationCategoryMutation, deleteInnovationMutation,
    getInnovationsData,
    updateInnovationData
} from "@/graphql/innovations/innovations.graphql";
import {notifyError, notifySuccess} from "@/plugins/notification.service";
import _ from "lodash";
import i18n from "@/i18n";

export default {
    state: {
        innovations: [],
        innovationCategories: [],
    },
    getters: {
        getInnovations: state => state.innovations,
    },
    actions: {
        async getInnovations(context, filtering = {}) {
            context.commit("setLoadingState", true);
            try {
                const output = await apolloClient.query({
                    fetchPolicy: "network-only",
                    query: getInnovationsData,
                    variables: { filtering }
                });

                let { response, data } = output.data?.getInnovationData;
                if (response?.status) {
                    context.commit("setStoreItem", {
                        module: "innovations",
                        entity: "innovations",
                        data
                    });
                    return data;
                } else {
                    await notifyError(response?.message || "Failed to fetch innovations");
                    return null;
                }
            } catch (error) {
                await notifyError(error.message || "An error occurred while fetching innovations");
                console.error(error);
                return null;
            } finally {
                context.commit("setLoadingState", false);
            }
        },



        async createInnovation(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: createInnovation,
                    variables: { input }
                })
                .then(output => {
                    let { response, data } = output.data.addInnovationMutation
                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "innovations",
                            entity: "innovations",
                            uniqueKey: "innovationUniqueId",
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

        async updateInnovation(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: updateInnovationData,
                    variables: { input }
                })
                .then(output => {
                    let { response, data } = output.data.updateInnovationMutation
                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "innovations",
                            entity: "innovations",
                            uniqueKey: "innovationUniqueId",
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

        async deleteInnovationMutation(context, innovationUniqueId){
            context.commit("setLoadingState", true);
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteInnovationMutation,
                variables: { innovationUniqueId }
            })
                .then(output => {
                    let { response, data } = output.data.deleteInnovationMutation
                    if (response.status) {
                        context.commit("deleteStoreItem", {
                            module: "innovations",
                            entity: "innovations",
                            uniqueKey: "id",
                            data,
                            remove : true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                }).finally(() => context.commit("setLoadingState", false));
        }
    }
}
