import gql from "graphql-tag";
import {paginationFields, responseField} from "@/graphql/utils/base.graphql";

export const researchArea = `
 id
                    researchAreaUniqueId
                    researchAreaName
                    researchAreaPhoto
                    researchAreaDescription
                    researchAreaIsActive
                    researchAreaType
                    researchAreaCreateddate
                    researchAreaCreatedBy {
                        id
                        profileUniqueId
                        username
                        firstName
                        middleName
                        lastName
                        profilePicture
                        gender
                        institution
                        role
                        dateJoined
                    }`

export const researchFields = `
id
                proposalUniqueId
                proposalTitle
                proposalDescription
                proposalArea {
                   ${researchArea}
                }
                proposalStage
                proposalStatus
                researchProposalDoc
                proposalAuthor {
                    id
                    profileUniqueId
                    username
                    firstName
                    middleName
                    lastName
                    profilePicture
                    gender
                    institution
                    role
                    dateJoined
                }
                proposalAuthorInstitution {
                    institutionUniqueId
                    institutionName
                    institutionType
                    institutionCreatedDate
                }
                proposalAuthorCountry {
                    countryUniqueId
                    countryName
                    countryCreatedDate
                }
                proposalIsActive
                proposalCreatedDate
                proposalAttachments {
                    id
                    proposalAttachmentUniqueId
                    proposalAttachmentName
                    proposalAttachmentPath
                    proposalAttachmentFileType
                    proposal
                    proposalAttachmentIsActive
                    proposalAttachmentIsDownloaded
                    proposalAttachmentCreatedDate
                }
                proposalComments {
                    id
                    commentUniqueId
                    comment
                    commentedBy {
                        id
                        profileUniqueId
                        username
                        firstName
                        middleName
                        lastName
                        profilePicture
                        gender
                        institution
                        role
                        dateJoined
                    }
                    commentIsActive
                    commentCreatedDate
                    }`

export const createOngoingResearch = gql`
    mutation CreateOngoingResearch($input: ResearchProposalInputObject) {
        createOngoingResearch(input: $input) {
            ${responseField}
           data{
               ${researchFields}
           }
        }
    }`

export const updateOngoingResearch = gql`
    mutation UpdateOngoingResearch($input: ResearchProposalInputObject) {
        updateOngoingResearch(input: $input) {
            ${responseField}
           data{
               ${researchFields}
           }
        }
    }`

export const getOnGoingResearchData = gql`
    query GetOnGoingResearchData($filtering: ResearchProposalFilteringbject) {
        getOnGoingResearchData(filtering: $filtering) {
           ${responseField}
            ${paginationFields}
            data {
                ${researchFields}
            }
            
        }
    }`


export const deleteOngoingResearch  = gql`
    mutation DeleteOngoingResearch($uniqueId: String!) {
        deleteOngoingResearch(uniqueId: $uniqueId) {
            ${responseField}
            data{
                ${researchFields}
            }
        }
    }`
