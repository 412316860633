import Vue from 'vue'
import VueRouter from 'vue-router'
import uaaRouter from '@/components/authentication/uaaRouter.js';
import mainRoutes from '@/components/mainRoutes.js';
// import store from '@/store';
import _ from 'lodash'
import store from '@/store';
import { notifyError, notifyInfo } from '@/plugins/notification.service';
import { canAccess } from '@/plugins/utilities';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import("@/components/Landing.vue"),
    meta: {
      permissions: []
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import("@/components/Home.vue"),
    meta: {
      permissions: []
    }
  },
  {
    path: "/diaspora",
    component: () => import("@/components/Diaspora.vue"),
    name: "Diaspora",
    meta: {
      permissions: []
    }
  },
  {
    path: "/security-trend/:securityTrendUniqueId",
    component: () => import("@/components/ViewTrend.vue"),
    name: "ViewTrendDetails",
    meta: {
      permissions: []
    }
  },
  {
    path: "/alumni",
    component: () => import("@/components/Alumni.vue"),
    name: "Alumni",
    meta: {
      permissions: []
    }
  },
  {
    path: "/innovations-and-technologies",
    component: () => import("@/views/landing/InnovationList.vue"),
    name: "InnovationsAndTechnologies",
    meta: {
      permissions: []
    }
  },
  {
    path: "/publicationsLanding",
    component: () => import("@/views/landing/research_publication/ResearchPublication.vue"),
    name: "publicationsLanding",
    meta: {
      permissions: []
    }
  },
  {
    path: "/publicationsLanding/research-details/:proposalUniqueId",
    component: () => import("@/views/landing/research_publication/ResearchDetails.vue"),
    name: "publicationsLanding",
    meta: {
      permissions: []
    }
  },
  {
    path: "/publication-details/:publicationUniqueId",
    component: () => import("@/views/landing/research_publication/PublicationDetails.vue"),
    name: "PublicationDetailsLanding",
    meta: {
      permissions: []
    }
  },
  {
    path: "/pict",
    component: () => import("@/views/landing/research_publication/ResearchPublication.vue"),
    name: "GG",
    meta: {
      permissions: []
    }
  },
  {
    path: "/gallery",
    name: "LandingPageGallery",
    component: () => import("@/views/landing/media-center/GalleryPage.vue"),
    meta: {
      permissions: []
    }
  },{
    path: "/about",
    name: "AboutPage",
    component: () => import("@/views/landing/about/AboutPage.vue"),
    meta: {
      permissions: []
    }
  },
  {
    path: "attachment-preview/:publicationUniqueId",
    name: "Attachment Preview",
    component: () => import("@/components/Research_Publication/PublicationPreview.vue"),
    meta: {
      permissions: []
    },
    props: true
  },
  {
    path: "research-preview/:proposalUniqueId",
    name: "Research Preview",
    component: () => import("@/components/Research_Publication/ResearchPreview.vue"),
    meta: {
      permissions: []
    },
    props: true
  },
  {
    path: "/diaspora-register",
    component: () => import("@/components/DiasporaRegistrationForm.vue"),
    name: "diaspora register",
    meta: {
      permissions: []
    }
  },
  ...uaaRouter,
  ...mainRoutes,
  // Add Other Routes ontop of NotFound
  // If route is not defined
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/shared/PageNotFound"),
    meta: {
      permissions: []
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = store.getters.getTokenState || null
  let { permissions } = to.meta;

    if (_.isNil(isAuthenticated) && !["Login", "Register", "Reset Password", "Password Reset Email", "Activate Account", "Landing", "Diaspora", "Alumni", "diaspora register","ViewTrendDetails","Publications","Attachment Preview","Research Preview" , "publicationsLanding", "LandingPageGallery", "AboutPage", "PublicationDetailsLanding", "InnovationsAndTechnologies"].includes(to.name)) {
    next({ name: 'Landing' });
  }
  else {
    if (!_.isNil(isAuthenticated) && ["Login", "Register", "Reset Password", "Password Reset Email", "Activate Account"].includes(to.name)) {
      next({ name: 'Home' });
    }
    if (!_.isNil(isAuthenticated)) {
      if (!canAccess(permissions)) {
        notifyInfo(`Page Not Found. Redirected`);
        next({ name: 'Home' });
      } else {
        next();
      }
    }
    else {
      next();
    }
  }
})
Vue.use(VueRouter)

export default router;
