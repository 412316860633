export const responseField = `
response {
      id
      status
      code
      message
    }`

export const paginationFields = `
page {
      number
      hasNextPage
      hasPreviousPage
      nextPageNumber
      previousPageNumber
    }`
