import {apolloClient} from "@/vue-apollo";
import {
    createInnovationCategoryMutation, deleteInnovationCategoryMutation,
    getInnovationCategoryData,
    updateInnovationCategoryMutation
} from "@/graphql/innovations/innovations.graphql";
import {notifyError, notifySuccess} from "@/plugins/notification.service";
import i18n from "@/i18n";
export default {
    state: {
        innovationCategories: [],
    },
    getters: {
        getInnovationCategories: state => state.innovationCategories,
    },
    actions: {
        async getInnovationCategories(context, filtering = {}) {
            context.commit("setLoadingState", true);
            try {
                const output = await apolloClient.query({
                    fetchPolicy: "network-only",
                    query: getInnovationCategoryData,
                    variables: { filtering }
                });

                let { response, data } = output.data.getInnovationCategoryData || [];
                if (response?.status) {
                    context.commit("setStoreItem", {
                        module: "innovationCategories",
                        entity: "innovationCategories",
                        data
                    });
                    console.log(data, '---response data')
                    return data;
                } else {
                    await notifyError(response?.message || "Failed to fetch innovation categories");
                    return null;
                }

            } catch (error) {
                await  notifyError(error.message || "An error occurred while fetching innovation categories");
                console.error(error);
                return null;
            } finally {
                context.commit("setLoadingState", false);
            }
        },

        async createInnovationCategories(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: createInnovationCategoryMutation,
                    variables: { input }
                })
                .then(output => {
                    let { response, data } = output.data.createInnovationCategoryMutation
                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "innovationCategories",
                            entity: "innovationCategories",
                            uniqueKey: "innovationCategoryUniqueId",
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

        async updateInnovationCategories(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateInnovationCategoryMutation,
                variables: { input }
            })
            .then(output => {
                let { response, data } = output.data.updateInnovationCategoryMutation;
                if (response.status) {
                    context.commit("setStoreItem", {
                        module: "innovationCategories",
                        entity: "innovationCategories",
                        uniqueKey: "innovationCategoriesUniqueId",
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                    return data;
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'));
                }
            }).finally(() => context.commit("setLoadingState", false));
        },

        async deleteInnovationCategoryMutation(context, uniqueId) {
            context.commit("setLoadingState", true);
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteInnovationCategoryMutation,
                variables: { uniqueId }
            })
            .then(output => {
                let { response, data } = output.data.deleteInnovationCategoryMutation
                console.log(data, '----response delete')
                if (response.status) {
                    context.commit("deleteStoreItem", {
                        module: "innovationCategories",
                        entity: "innovationCategories",
                        uniqueKey: "id",
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                    return data;
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'));
                }
            }).finally(() => context.commit("setLoadingState", false));
        }

    }
}
