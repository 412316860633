export default [
    {
        path: "innovations-and-technologies",
        name: "Innovations&TechnologiesDashboard",
        component: () =>import("./InnovationsAndTechnologiesList.vue"),
        meta: {
            permissions: []
        }
    },
    {
        path: "innovation-categories",
        name: "Innovations&TechnologiesCategoriesDashboard",
        component: () =>import("./innovation-categories/InnovationCategoriesList.vue"),
        meta: {
            permissions: []
        }
    },
    {
        path: "innovations-and-technologies/:innovationUniqueId",
        name: "Innovations&TechnologiesDashboardDetails",
        component: () =>import("./InnovationDetails.vue"),
        meta: {
            permissions: []
        }
    },
]
