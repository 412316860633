import Vue from "vue";
import Vuex from "vuex";
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
import { cloneDeep, forOwn, isNil } from "lodash";
import authentication from "@/store/Authentication/authentication.js"
import countryManagement from "./Settings/countryManagement";
import programCategoryManagement from "./Settings/programCategoryManagement";
import institutionManagement from "./Settings/institutionManagement";
import regionManagement from "./Settings/regionManagement";
import educationLevelManagement from "./Settings/educationLevelManagement";
import languageManagement from "./Settings/languageManagement";
import programManagement from "./Settings/programManagement";
import refereeManagement from "./registration/refereeManagement";
import roleManagement from "./Settings/roleManagement";
import skillCategoryManagement from "./Settings/skillCategoryManagement";
import skillSubCategoryManagement from "./Settings/skillSubCategoryManagement";
import workingExperienceDetailManagement from "./registration/workingExperienceDetailManagement";
import districtManagement from "./Settings/districtManagement";
import wardManagement from "./Settings/wardManagement";
import contactDetailsManagement from "./registration/contactDetailsManagement";
import trainingDetailsManagement from "./registration/trainingDetailsManagement";
import projectPortifolio from "@/store/registration/projectPortifolio.js";
import skillQualification from "@/store/registration/skillQualification.js";
import languageProficiencyManagement from "./registration/languageProficiencyManagement";
import attachmentManagement from "./Settings/attachmentManagement";
import professionalQualificationsManagement from "./registration/professionalQualificationsManagement";
import accademicQualificationManagement from "./registration/accademicQualificationManagement.js";
import applicationDetails from "./Application/applicationDetails.js";
import personalCvManagement from "./registration/personalCvManagement";
import applicationManagement from "./Application/applicationManagement";

import enums from "./enums/enums";
import healthInsuranceManagement from "./registration/healthInsuranceManagement";
import publicationManagement from "./registration/publicationManagement";
import applicationPostDetailsManagement from "./Application/applicationPostDetailsManagement";
import declaration from "@/store/registration/declaration.js"
import applicationRequestManagement from "@/store/Application/applicationRequestManagement.js";
import profileWithAttachmentManagement from "./registration/profileWithAttachmentManagement.js";
import projectCategoryManagement from "./Settings/projectConfigurations/projectCategoryManagement";
import projectNatureManagement from "./Settings/projectConfigurations/projectNatureManagement";
import ideaDetails from "./ideas/ideaDetails";
import projectManagement from "./Project/projectManagement";
import projectTaskManagement from "./Project/projectTaskManagement";
import projectSubTasksManagement from "./Project/projectSubTasksManagement";
import ideaTask from "./ideas/ideaTask";
import ideaSubtask from "./ideas/ideaSubtask";
import requestManagement from "@/store/communication/requestManagent.js";
import announcementManagement from "@/store/communication/announcementManagement";
import projectMemberRoleManagement from "@/store/Settings/projectConfigurations/projectMemberRoleManagement";
import nonStaffUserManagement from "@/store/human_resource/nonStaffUserManagement";
import ideaMember from "./ideas/ideaMember";
import projectMemberManagement from "@/store/Project/projectMemberManagement";
import outsideWorkManagement from "@/store/Project/outsideWork/outsideWorkManagement";
import TaskAssignment from "./ideas/TaskAssignment";
import outsideWorkTaskManagement from "@/store/Project/outsideWork/outsideWorkTaskManagement";
import attendanceManagement from "@/store/attendance/attendanceManagement";
import enrollmentManagement from "@/store/attendance/enrollmentManagement";
import ideaConceptualization from "./ideas/ideaConceptualization";
import ideaProgress from "./ideas/ideaProgress";
import ideaMatuarity from "./ideas/ideaMatuarity";
import awarenessSession from "./communication/awarenessSession";
import conductedTraining from "./communication/conductedTraining";
import meetingMinuteManagement from "@/store/repository/meetingMinuteManagement";
import advertisementManagement from "@/store/communication/advertisementManagement";
import ideaSubtaskProgress from "./ideas/ideaSubtaskProgress";
import assignTasksManagement from "../store/Project/assignTask/assignTasksManagement"
import userManagement from "./Settings/userManagement";
import assignSubTasksManagement from "./Project/assignTask/assignSubTasksManagement";
import taskProgressManagement from './Project/taskProgressManagement.js';
import subTaskProgressManagement from './Project/subTaskProgressManagement.js';
import yearsManagement from './Application/yearsManagement.js';
import deviceEnrollmentManagement from "./attendance/deviceEnrollmentManagement";
import applicationFeedbackManagement from "./Application/applicationFeedbackManagement.js";
import galleryManagement from "./repository/galleryManagement.js";
import galleryCollectionManagement from "./repository/galleryCollectionManagement.js";
import notificationsManagement from "./repository/notificationsManagement.js";
import applicationDashboardManagement from "./Application/applicationDashboardManagement.js"
import institutionWithProgram from "./Settings/institutionWithProgram.js";
import individualAttendanceDashboardManagement from "./attendance/individualAttendanceDashboardManagement";
import attendanceDashboardManagement from "./attendance/attendanceDashboardManagement";
import galleryPublicCollection from "./repository/galleryPublicCollection.js";
import projectPortfolioManagement from "./Settings/projectConfigurations/projectPortfolioManagement";
import projectComponentManagement from "./Project/projectComponentManagement.js"
import projectFolderManagement from "./Project/projectFolderManagement";
import folderFile from "./Project/folderFile";
import applicationCategoryReportManagement from "./Report/applicationCategoryReportManagement";
import selectedStudentsReportManagement from "./Report/selectedStudentsReportManagement";
import institutionalReportManagement from "./Report/institutionalReportManagement";
import userInformationManagement from "./user_information/userInformationManagement";
import projectDashboard from "./Project/projectDashboard";
import weeklyReport from "./Project/weeklyReport";
import userInUserInformationManagement from "./user_information/userInUserInformationManagement";
import projectReportManagement from "./Report/projectReportManagement";
import aluminum from "./communication/aluminum";
import applicationCategory from "./Settings/applicationCategory";
import alumniSkillCategories from "./Settings/alumniSkillCategories";
import alumniSubSkillsCategories from "./Settings/alumniSubSkillsCategories";
import diasporaExpertSettingsManagement from "@/store/Settings/diasporaExpertSettingsManagement";
import diasporaIntrestsManagement from "@/store/Settings/diasporaIntrestsManagement";
import AlumniManagement from "@/store/MemberShips/Alumnni/AlumniManagement";
import DiasporaManagement from "@/store/MemberShips/Diaspora/DiasporaManagement";
import researchManagement from "@/store/communication/researchManagement"
import securityManagement from "@/store/communication/securityManagement"
import currentMember from "@/store/MemberShips/Current/currentMember";
import ResecherTypeManagement from "@/store/Settings/ResecherTypeManagement";
import ResearchProposalAttachment from "@/store/ResearchProposal/ResearchProposalAttachment";
import ResearchProposalManagement from "@/store/ResearchProposal/ResearchProposalManagement";
import SurveyManagement from "@/store/ResearchProposal/SurveyManagement";
import applicationLetter from "./Application/applicationLetter";
import ProjectPtReport from "@/store/Project/projectPtReport";
import applicationBatchApprovalManagement from '@/store/Application/applicationBatchApprovalManagement'
import innovations from "@/store/innovations/innovations";
import innovationCategories from "@/store/innovations/innovationCategories";

let ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: process.env.VUE_APP_STR_PWD,
});
Vue.use(Vuex);
export const initialStoreModules = {
  // Define all store modules here to enable store clearing when needed
  authentication,
  countryManagement,
  programCategoryManagement,
  institutionManagement,
  regionManagement,
  educationLevelManagement,
  programManagement,
  languageManagement,
  refereeManagement,
  roleManagement,
  skillCategoryManagement,
  skillSubCategoryManagement,
  workingExperienceDetailManagement,
  districtManagement,
  wardManagement,
  contactDetailsManagement,
  projectPortifolio,
  trainingDetailsManagement,
  skillQualification,
  languageProficiencyManagement,
  healthInsuranceManagement,
  enums,
  publicationManagement,
  attachmentManagement,
  professionalQualificationsManagement,
  accademicQualificationManagement,
  declaration,
  applicationPostDetailsManagement,
  applicationManagement,
  applicationDetails,
  applicationLetter,
  applicationRequestManagement,
  profileWithAttachmentManagement,
  personalCvManagement,
  projectCategoryManagement,
  projectNatureManagement,
  ideaDetails,
  projectManagement,
  projectTaskManagement,
  projectSubTasksManagement,
  ideaTask,
  ideaSubtask,
  requestManagement,
  announcementManagement,
  projectMemberRoleManagement,
  nonStaffUserManagement,
  ideaMember,
  projectMemberManagement,
  outsideWorkManagement,
  TaskAssignment,
  outsideWorkTaskManagement,
  attendanceManagement,
  enrollmentManagement,
  ideaConceptualization,
  ideaProgress,
  ideaMatuarity,
  awarenessSession,
  conductedTraining,
  meetingMinuteManagement,
  advertisementManagement,
  ideaSubtaskProgress,
  assignTasksManagement,
  userManagement,
  assignSubTasksManagement,
  taskProgressManagement,
  subTaskProgressManagement,
  yearsManagement,
  deviceEnrollmentManagement,
  applicationFeedbackManagement,
  galleryManagement,
  galleryCollectionManagement,
  notificationsManagement,
  institutionWithProgram,
  applicationDashboardManagement,
  individualAttendanceDashboardManagement,
  attendanceDashboardManagement,
  galleryPublicCollection,
  projectPortfolioManagement,
  projectComponentManagement,
  projectFolderManagement,
  folderFile,
  applicationCategoryReportManagement,
  selectedStudentsReportManagement,
  institutionalReportManagement,
  userInformationManagement,
  projectDashboard,
  weeklyReport,
  userInUserInformationManagement,
  projectReportManagement,
  aluminum,
  applicationCategory,
  alumniSubSkillsCategories,
  alumniSkillCategories,
  diasporaExpertSettingsManagement,
  diasporaIntrestsManagement,
  AlumniManagement,
  DiasporaManagement,
  researchManagement,
  securityManagement,
  currentMember,
  ResecherTypeManagement,
  ResearchProposalAttachment,
  ResearchProposalManagement,
  SurveyManagement,
  ProjectPtReport,
  applicationBatchApprovalManagement,
  innovations,
  innovationCategories,
};
export default new Vuex.Store({
  modules: cloneDeep(initialStoreModules),
  state: {
    isLoading: false,
    nightMode: false,
    fileDialog: false,
    encodedFilePreview: "",
    paginator: {},
    applicationRequestFilters: {}
  },
  getters: {
    getLoadingState: (state) => state.isLoading,
    getNightMode: (state) => state.nightMode,
    getFileDialog: (state) => state.fileDialog,
    getEncodedFilePreview: (state) => state.encodedFilePreview,
    getPaginator: (state) => state.paginator,
    getApplicationRequestFilters: (state) => state.applicationRequestFilters,
  },
  mutations: {
    clearFileUploadResponse: (state) => (state['attachmentManagement']['fileUploadResponse'] = []),
    removeFileUploadResponse: (state, item) => {
      let index = state['attachmentManagement']['fileUploadResponse'].findIndex((el) => (el['id'] == item['id']));
      if (index != -1) {
        state['attachmentManagement']['fileUploadResponse'].splice(index, 1)
      }
      state['attachmentManagement']['fileUploadResponse'] = [...state['attachmentManagement']['fileUploadResponse']]
    },
    setFileDialog: (state, val) => state.fileDialog = val,
    setEncodedFilePreview: (state, val) => state.encodedFilePreview = val,
    setLoadingState: (state, loading) => (state.isLoading = loading),
    RESET_STATE(state) {
      forOwn(initialStoreModules, (value, key) => {
        state[key] = cloneDeep(value.state);
      });
    },
    updateStoreItem: (state, item) => {
      let { module, entity, uniqueKey, data, remove } = item;
      let index = state[module][entity].findIndex((el) => (el[uniqueKey] == data[uniqueKey]));
      if (index != -1) {
        if (remove) {
          state[module][entity].splice(index, 1)
        } else {
          state[module][entity][index] = data
        }
      } else {
        state[module][entity].unshift(data)
      }
      state[module][entity] = [...state[module][entity]]
    },
    deleteStoreItem: (state, item) => {
      let { module, entity, uniqueKey, data, remove } = item;
      // Ensure the module and entity exist before attempting to delete
      if (state[module] && Array.isArray(state[module][entity])) {
        state[module][entity] = state[module][entity].filter(
            (storeItem) => storeItem[uniqueKey] !== data[uniqueKey]
        );
      }
    },
    setStoreItem: (state, item) => {
      let { module, entity, data } = item
      if (isNil(module) || module?.toLowerCase() == 'root') {
        state[entity] = data
      } else {
        state[module][entity] = data
      }
    },
  },
  plugins: [
    createPersistedState({
      key: "EG",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
