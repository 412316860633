import "regenerator-runtime";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/utilities.js'
import './assets/js/jquery.nav.js'
import './assets/js/custom.js'
import './assets/tailwind.css';

import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { apolloProvider } from "./vue-apollo";
import VueApexCharts from "vue-apexcharts";


Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  i18n,
  render: h => h(App)
}).$mount('#app')
